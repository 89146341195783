.sv-section {
	&.sv--split {
		&.sv--helix {
			.sv--content {
				grid-template-columns: 20% auto 0;
				align-items: center;
				justify-content: center;
			}
			.sv--split-primary {
				max-width: 27em;
				padding: 1rem;
				text-align: center;
				font: {
					weight: 300;
					size: 1.15em;
				}
				color: black;
				@media (min-width: 1024px) {
					padding: unset;
				}
			}
			.sv--learn_more {
				font-style: italic;
			}
			&.sv--reverse {
				.sv--content {
					grid-template-columns: auto 20%;
				}
			}
		}
		&.sv--block {
			margin: unset;
			background-color: #668AAC;
			.sv--content {
				column-gap: 0;
				grid-template-columns: 2fr 3fr;
			}
			.sv--split-primary {
				display: flex;
				flex-direction: column;
				justify-content: center;
				padding: 3rem 2rem;
				font-family: "Source Sans Pro", "Arial", sans-serif;
				font-size: 0.95em;
				color: white;
				text-align: center;
				h3 {
					font-weight: 300;
					font-size: 1.25em;
					text-transform: uppercase;
					strong {
						font-weight: 600;
						margin-right: 1ex;
					}
				}
			}
			.sv--split-secondary {
				img {
					display: block;
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: left top;
				}
			}
		}
	}
}